import { DestroyRef, Directive, ElementRef, Input, inject, type OnChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventBusService, type TrackingServiceToolFieldChangeEvent, type TrackingServiceToolFieldCorrectionEvent } from '@big-direkt/event-bus';
import { type TypedSimpleChanges } from '@big-direkt/utils/shared';
import { fromEvent } from 'rxjs';
import { ServiceToolFieldTrackingModel } from '../models/service-tool-field-tracking.model';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[bigStFieldTracking]',
})
export class ServiceToolFieldTrackingDirective implements OnChanges {
    private readonly millisecondsDivider = 1000;
    private readonly eventBus = inject(EventBusService);
    private readonly destroyRef = inject(DestroyRef);

    @Input('bigStFieldTracking') public model?: ServiceToolFieldTrackingModel;

    private focusStartTime?: number;
    private corrected = false;

    public constructor(private readonly elementRef: ElementRef) {
        this.registerEventListener(elementRef);
    }

    public ngOnChanges(changes: TypedSimpleChanges<ServiceToolFieldTrackingDirective>): void {
        if (changes.model?.previousValue && changes.model.currentValue?.valid && !changes.model.previousValue.valid && !this.corrected) {
            this.corrected = true;
            this.createAndTrackEvent('st_field_correction_event', changes.model.currentValue);
        }
    }

    private registerEventListener(elementRef: ElementRef): void {
        const focus$ = fromEvent<FocusEvent>(elementRef.nativeElement, 'focus', { capture: true });
        const change$ = fromEvent<Event>(elementRef.nativeElement, 'change', { capture: true });

        focus$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            if (!this.model) {
                return;
            }

            this.focusStartTime = new Date().getTime();
        });

        change$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            if (!this.model) {
                return;
            }

            this.corrected = false;
            this.createAndTrackEvent('st_field_change_event', this.model);
        });
    }

    private createAndTrackEvent(
        key: TrackingServiceToolFieldChangeEvent['key'] | TrackingServiceToolFieldCorrectionEvent['key'],
        model: ServiceToolFieldTrackingModel,
    ): void {
        const trackingEvent: TrackingServiceToolFieldChangeEvent['data'] | TrackingServiceToolFieldCorrectionEvent['data'] = {
            id: model.metadata.id,
            name: model.metadata.name,
            state: model.metadata.state,
            insuredPerson: model.insuredPerson,
            fieldName: model.fieldName,
            triggerElement: this.elementRef.nativeElement.tagName,
            duration: (new Date().getTime() - (this.focusStartTime ?? 0)) / this.millisecondsDivider,
        };

        this.eventBus.emit({
            key,
            data: trackingEvent,
        });
    }
}
