import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, type AbstractControl } from '@angular/forms';
import { EventBusService } from '@big-direkt/event-bus';
import { CustomErrorHandlerService } from '@big-direkt/utils/environment';
import { TranslocoService } from '@jsverse/transloco';
import { PopupClickTrackingData } from '../models/popup-click-tracking-data.model';
import { ResultClickTrackingData } from '../models/result-click-tracking-data.model';
import { type ServiceToolBase } from '../models/service-tool-base.model';
import { type ServiceToolMetadata } from '../models/service-tool-metadata.model';
import { type TypedOrUntypedAbstractControl } from '../models/typed-or-untyped-abstract-control.model';

@Component({
    selector: 'big-service-tool-base',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class ServiceToolBaseComponent<T extends { [K in keyof T]: AbstractControl<any, any> }> implements ServiceToolBase {
    protected readonly formBuilder = inject(FormBuilder);
    private readonly eventBus = inject(EventBusService);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandler = inject(CustomErrorHandlerService);
    private readonly impressionEvaluationDuration = 3000;

    public metadata!: ServiceToolMetadata;
    public form: FormGroup<T> | undefined;
    public hasBeenSubmitted = false;
    public translationScope: string | undefined = undefined;

    private isInViewport = false;

    public setMetadata(metadata: ServiceToolMetadata): void {
        // First initial call on creation is undefined
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.metadata && this.metadata.state !== metadata.state && metadata.accordionTitle) {
            this.trackStShowOrHideContentEvent(metadata);
        }

        this.metadata = metadata;
    }

    public setIsInViewport(newValue: boolean): void {
        if (!this.isInViewport && newValue) {
            this.evaluateImpressionAfter3Seconds();
        }

        this.isInViewport = newValue;
    }

    public trackStInfoIconEvent(fieldName: string): void {
        this.eventBus.emit({
            key: 'st_info_icon_event',
            data: {
                id: this.metadata.id,
                name: this.metadata.name,
                state: this.metadata.state,
                fieldName,
            },
        });
    }

    public trackStFormSubmitEvent(resultNumber: number | undefined = undefined): void {
        const invalidLabels: string[] = [];
        const controls = this.getFormControls();
        this.findInvalidControlLabels(controls, invalidLabels);
        const submitState = invalidLabels.length > 0 ? 'failed' : 'ok';
        const clickLabel = this.translocoService.translate(this.translateLabel('submit'));

        this.eventBus.emit({
            key: 'st_form_submit_event',
            data: {
                id: this.metadata.id,
                name: this.metadata.name,
                state: this.metadata.state,
                clickLabel,
                submitState,
                resultNumber,
                errorFields: invalidLabels.length > 0 ? invalidLabels.join(',') : undefined,
            },
        });
    }

    public trackStResultClickEvent(data: ResultClickTrackingData): void {
        const specificName = `${this.metadata.name}: ${data.tableHeader}`;

        this.eventBus.emit({
            key: 'st_result_click_event',
            data: {
                id: this.metadata.id,
                name: specificName,
                state: this.metadata.state,
                clickLabel: data.clickLabel,
                resultNumber: data.resultNumber,
                resultClickType: data.resultClickType,
            },
        });
    }

    public trackStPopupClickEvent(data: PopupClickTrackingData): void {
        this.eventBus.emit({
            key: 'st_popup_click_event',
            data: {
                clickLabel: data.clickLabel,
                popupDuration: data.duration,
                id: this.metadata.id,
                state: this.metadata.state,
                name: this.metadata.name,
                resultNumber: data.resultNumber,
            },
        });
    }

    /**
     * Can be overwritten to handle multiple forms in one component
     * @returns TypedOrUntypedAbstractControl<T> | undefined
     */
    protected getFormControls(): TypedOrUntypedAbstractControl<T> | undefined {
        return this.form?.controls;
    }

    private findInvalidControlLabels(controls: TypedOrUntypedAbstractControl<T> | undefined, invalidLabels: string[]): string[] {
        // eslint-disable-next-line no-restricted-syntax
        for (const name in controls) {
            if (Object.prototype.hasOwnProperty.call(controls, name)) {
                const currentControl = controls[name];
                if (currentControl instanceof FormGroup) {
                    return this.findInvalidControlLabels(currentControl.controls as TypedOrUntypedAbstractControl<T>, invalidLabels);
                }

                if (controls[name].invalid) {
                    invalidLabels.push(this.translateLabel(name));
                }
            }
        }

        return invalidLabels;
    }

    private evaluateImpressionAfter3Seconds(): void {
        setTimeout(() => {
            if (this.isInViewport) {
                this.trackStImpressionEvent();
            }
        }, this.impressionEvaluationDuration);
    }

    private trackStImpressionEvent(): void {
        this.eventBus.emit({
            key: 'st_impression_event',
            data: {
                id: this.metadata.id,
                name: this.metadata.name,
                state: this.metadata.state,
            },
        });
    }

    private trackStShowOrHideContentEvent(metadata: ServiceToolMetadata): void {
        if (!metadata.accordionTitle) {
            return;
        }

        this.eventBus.emit({
            key: 'st_show_or_hide_content_event',
            data: {
                id: metadata.id,
                name: metadata.name,
                state: metadata.state,
                accordionTitle: metadata.accordionTitle,
            },
        });
    }

    private translateLabel(key: string): string {
        if (!this.translationScope) {
            const error = new Error('translation scope was not defined in class that extends this base class. Please override the translation scope correctly');

            this.errorHandler.handleError(error);
        }

        return this.translocoService.translate(`${this.translationScope}.label.${key}`);
    }
}
